import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Link from 'next/link'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  footerLinks: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  footerItem: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  footerCopy: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default function Footer () {
  const classes = useStyles()

  return (
    <Container maxWidth='lg' component='footer' className={classes.footer}>
      <Divider />
      <Typography variant='body1' className={classes.footerLinks}>
        <Link href='/contact'>
          <a className={classes.footerItem}>Kontakt</a>
        </Link>
        <span className={classes.divider}>|</span>
        <Link className={classes.footerItem} href='/faq'>
          <a className={classes.footerItem}>Häufige&nbsp;Fragen</a>
        </Link>
        <span className={classes.divider}>|</span>
        <Link className={classes.footerItem} href='/impressum'>
          <a className={classes.footerItem}>Impressum</a>
        </Link>
        <span className={classes.divider}>|</span>
        <Link className={classes.footerItem} href='/datenschutz'>
          <a className={classes.footerItem}>Datenschutz</a>
        </Link>
        <span className={classes.divider}>|</span>
        <a href='https://www.live-tv-jetzt.de' className={classes.footerItem}>live-tv-jetzt.de</a>
      </Typography>
      <div className={classes.footerCopy}>
        &copy; 2024 Sendung verpasst UG
      </div>
    </Container>
  )
}
